<!-- 票据充值 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20">
			<div class="margin-t-15 flex-row-start-between">
				<div class="font-size18 font-color-333 font-weight700 ">
					票据充值
				</div>
				<div class="background-color-theme add-recharge-tickte flex-row-center-center pointer" @click="addRechargeTicket">
					新增票据充值
				</div>
			</div>
			<el-tabs type="border-card" class="recharge-tickte-tabs margin-t-10">
				<el-tab-pane label="可用票据">
					<div v-for="(item,index) in availableTickteList" :key="'available'+index" class="tickte-list-box border-F2F2F2 margin-b-13 bg-fff">
						<div class="flex-row-space-between-center font-size13 font-color-666">
							<div>票据号：{{item.ticketNumber}}</div>
							<div>交票日：{{item.deliveryTicketDate}}</div>
						</div>
						<div class="flex-row-space-between tickte-list-inner-box margin-t-5">
							<div class="padding-t-5" style="display: flex;flex-direction: column;justify-content: space-between;">
								<div class="flex-row-align-center">
									<div class="flex-row-align-center issue-tickets-box">
										<div class="margin-r-10 font-size13 font-color-000 font-weight700">出票金额</div>
										<div class="font-size18 font-color-FF0000 font-weight700">{{item.invoiceAmount|NumFormat}}</div>
									</div>
									<div v-if="item.balance" class="flex-row-align-center">
										<div class="margin-r-10 font-size13 font-color-000 font-weight700">余额</div>
										<div class="font-size18 color-theme font-weight700">{{item.balance|NumFormat}}</div>
									</div>
								</div>
								<div class="tickets-expire-box flex-row-align-center font-color-666">
									<div class="iconfont icon-shijian margin-r-5"></div>
									<div class="font-size13">票据到期日：{{item.dueDate}}</div>
								</div>
							</div>
							<div class="flex-row-align-flex-end position-relative">
								<div v-if="item.balance" class="pointer tickte-detailed border-color-theme color-theme flex-row-center-center font-size13" round
								 @click="toDetailed">
									明细
								</div>
								<div v-else class="iconfont icon-shenhezhong shenhezhong position-absolute"></div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="已用完票据">
					<div v-for="(item,index) in beenTickteList" :key="'been'+index" class="tickte-list-box border-F2F2F2 margin-b-13 bg-fff">
						<div class="flex-row-space-between-center font-size13 font-color-666">
							<div>票据号：{{item.ticketNumber}}</div>
							<div>交票日：{{item.deliveryTicketDate}}</div>
						</div>
						<div class="flex-row-space-between tickte-list-inner-box margin-t-5">
							<div class="padding-t-5" style="display: flex;flex-direction: column;justify-content: space-between;">
								<div class="flex-row-align-center">
									<div class="flex-row-align-center issue-tickets-box">
										<div class="margin-r-10 font-size13 font-color-000 font-weight700">出票金额</div>
										<div class="font-size18 font-color-FF0000 font-weight700">{{item.invoiceAmount|NumFormat}}</div>
									</div>
									<div v-if="item.balance" class="flex-row-align-center">
										<div class="margin-r-10 font-size13 font-color-000 font-weight700">余额</div>
										<div class="font-size18 color-theme font-weight700">{{item.balance|NumFormat}}</div>
									</div>
								</div>
								<div class="tickets-expire-box flex-row-align-center font-color-666">
									<div class="iconfont icon-shijian margin-r-5"></div>
									<div class="font-size13">票据到期日：{{item.dueDate}}</div>
								</div>
							</div>
							<div class="flex-row-align-flex-end position-relative">
								<div v-if="item.balance" class="pointer tickte-detailed border-color-theme color-theme flex-row-center-center font-size13" round
								 @click="toDetailed">
									明细
								</div>
								<div v-else class="iconfont icon-shenhezhong shenhezhong position-absolute"></div>
							</div>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>

		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				availableTickteList: [{
						ticketNumber: "2020080312458976458796234516",
						deliveryTicketDate: "2020-04-02",
						dueDate: "2020-04-02",
						invoiceAmount: '235060',
						balance: '98560.5',
					},
					{
						ticketNumber: "2020080312458976458796234516",
						deliveryTicketDate: "2020-04-02",
						dueDate: "2020-04-02",
						invoiceAmount: '235060',
						balance: '98560.5',
					},
					{
						ticketNumber: "2020080312458976458796234516",
						deliveryTicketDate: "2020-04-02",
						dueDate: "2020-04-02",
						invoiceAmount: '235060',
						balance: '98560.5',
					},
					{
						ticketNumber: "2020080312458976458796234516",
						deliveryTicketDate: "2020-04-02",
						dueDate: "2020-04-02",
						invoiceAmount: '235060',
					},
					{
						ticketNumber: "2020080312458976458796234516",
						deliveryTicketDate: "2020-04-02",
						dueDate: "2020-04-02",
						invoiceAmount: '235060',
					},
					{
						ticketNumber: "2020080312458976458796234516",
						deliveryTicketDate: "2020-04-02",
						dueDate: "2020-04-02",
						invoiceAmount: '235060',
					}
				],
				beenTickteList: [],
			};
		},
		mounted() {

		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			//新增票据充值
			addRechargeTicket() {
				console.log("新增票据充值");
				this.$router.push({
					path: '/businessme/RechargeTicketForm',
					query: ''
				});
			},
			//明细
			toDetailed() {
				console.log("明细");
				this.$router.push({
					path: '/businessme/TicketDetailed',
					query: ''
				});
			}

		}

	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.add-recharge-tickte {
		padding: 0;
		width: 110px;
		height: 30px;
		border-radius: 2px;
	}

	.tickte-list-box {
		width: 885px;
		height: 79px;
		border-radius: 10px;
		padding: 15px;
	}

	.el-tabs--border-card {
		box-shadow: none;
		border: none;
	}

	.tickte-list-inner-box {
		height: 56px;
	}

	.issue-tickets-box {
		width: 294px;
	}

	.tickets-expire-box {
		height: 15px;
	}

	.tickte-detailed {
		width: 73px;
		height: 22px;
		border-radius: 26px;
		z-index: 2;
	}

	.tickets-expire-box>.iconfont {
		font-size: 15px;
	}

	.shenhezhong {
		font-size: 56px !important;
		color: #FED3BE;
		top: 0;
		right: 0;
	}

	/deep/.el-tabs__header {
		border: 1px solid #F2F2F2;
	}

	/deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
		border-right-color: #F2F2F2;
		border-left-color: #F2F2F2;

		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	/deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item {
		color: #333333;
	}

	/deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item:hover {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	/deep/ .el-tabs__content {
		padding: 10px 0;
	}

	.recharge-tickte-tabs {
		width: 917px;
	}
</style>
